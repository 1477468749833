import { ApiMediaV2 } from 'farcaster-client-data';

const CLOUDINARY_PREFIX =
  'https://res.cloudinary.com/merkle-manufactory/image/fetch';

export const WARPCAST_CLOUDFLARE_CDN_PREFIX =
  'https://imagedelivery.net/BXluQx4ige9GuW0Ia56BHw';

const WRPCDN_PREFIX = 'https://wrpcd.net/cdn-cgi/image';

const wrapWithWrpCdnForPrefetching = ({ url }: { url: string }) => {
  return 'https://wrpcd.net/cdn-cgi/imagedelivery' + new URL(url).pathname;
};

const getCloudflareImageUrl = ({
  url,
  windowWidth,
  width,
  blockAnimated = true,
  increasedWidth = false,
  applyWidthLimits = false,
}: {
  url: string;
  windowWidth: number;
  width?: number;
  blockAnimated?: boolean;
  increasedWidth?: boolean;
  applyWidthLimits?: boolean;
}) => {
  const extension =
    (url.split(/[#?]/, 2) || [])[0]?.split('.')?.pop()?.trim() || '';
  if (extension === 'svg') {
    const filters = ['c_fill', 'f_png'];
    if (width) {
      filters.push(`w_${width}`);
    }
    return `${CLOUDINARY_PREFIX}/${filters.join(',')}/${encodeURIComponent(
      url,
    )}`;
  }

  const wMulti = applyWidthLimits ? 1 : 3;
  const w = Math.round(width || windowWidth) * wMulti;
  const maxW = increasedWidth ? Math.max(1200, w) : w;

  const optionsArray = ['fit=contain', 'f=auto', `w=${maxW}`];

  if (blockAnimated) {
    optionsArray.unshift('anim=false');
  }

  const options = optionsArray.join(',');

  if (
    url.startsWith(WARPCAST_CLOUDFLARE_CDN_PREFIX) &&
    (url.endsWith('/original') ||
      url.endsWith('/rectcontain1') ||
      url.endsWith('/rectcontain2') ||
      url.endsWith('/rectcontain3') ||
      url.endsWith('/rectcrop1') ||
      url.endsWith('/rectcrop2') ||
      url.endsWith('/rectcrop3') ||
      url.endsWith('/squarecrop1') ||
      url.endsWith('/squarecrop2') ||
      url.endsWith('/squarecrop3') ||
      url.endsWith('/squarecover1') ||
      url.endsWith('/squarecover2') ||
      url.endsWith('/squarecover3'))
  ) {
    const path = url
      .replace(WARPCAST_CLOUDFLARE_CDN_PREFIX, '')
      .replace('/original', `/${options}`)
      .replace('/rectcontain1', `/${options}`)
      .replace('/rectcontain2', `/${options}`)
      .replace('/rectcontain3', `/${options}`)
      .replace('/rectcontain1', `/${options}`)
      .replace('/rectcrop1', `/${options}`)
      .replace('/rectcrop2', `/${options}`)
      .replace('/rectcrop3', `/${options}`)
      .replace('/squarecrop1', `/${options}`)
      .replace('/squarecrop2', `/${options}`)
      .replace('/squarecrop3', `/${options}`)
      .replace('/squarecover1', `/${options}`)
      .replace('/squarecover2', `/${options}`)
      .replace('/squarecover3', `/${options}`);

    return `https://wrpcd.net/cdn-cgi/imagedelivery/BXluQx4ige9GuW0Ia56BHw${path}`;
  }

  // Cloudflare image but not Warpcast - Cloudflare won't allow re-transform so attempt
  // to transform from those serving these images.
  if (
    url.startsWith('https://imagedelivery.net') &&
    url.indexOf('BXluQx4ige9GuW0Ia56BHw') === -1
  ) {
    return url
      .replace('/original', `/${options}`)
      .replace('/public', `/${options}`);
  }

  return `${WRPCDN_PREFIX}/${options}/${encodeURIComponent(url)}`;
};

const clamp = ({ v, min, max }: { v: number; min: number; max: number }) => {
  return Math.min(max, Math.max(min, v));
};

const getImageAspectRatio = ({ w, h }: { w: number; h: number }) => {
  if (w === 0 || h === 0) {
    return 1;
  }
  return clamp({ v: w / h, min: 0.33, max: 10 });
};

export type ClientProcessedMedia = {
  width: number;
  thumbnail: string;
  original: string;
  aspectRatio: number;
};

const pdToPx = [75, 150, 225];

// Below are the available variants as of Jan 2024 for images.
// original
// rectcontain1
// rectcontain2
// rectcontain3
// rectcover1
// rectcover2
// rectcover3
// rectcrop1
// rectcrop2
// rectcrop3
// rectscaledown1
// rectscaledown2
// rectscaledown3
// squarecover1
// squarecover2
// squarecover3
// squarecrop1
// squarecrop2
// squarecrop3
const processMediasForRendering = ({
  medias,
  pixelDensity,
  blockAnimated = true,
  increasedWidth = false,
  slowConnection = false,
}: {
  medias: ApiMediaV2[];
  pixelDensity: number;
  blockAnimated?: boolean;
  increasedWidth?: boolean;
  slowConnection?: boolean;
}): ClientProcessedMedia[] => {
  const processed: ClientProcessedMedia[] = [];

  const pd = Math.max(
    Math.min(Math.floor(pixelDensity), 1),
    slowConnection ? 2 : 3,
  );

  const px = pdToPx[pd - 1];

  for (const media of medias) {
    let url = media.staticRaster;
    let thumbnail = media.staticRaster;

    if (url.startsWith(WARPCAST_CLOUDFLARE_CDN_PREFIX)) {
      url = 'https://wrpcd.net/cdn-cgi/imagedelivery' + new URL(url).pathname;

      thumbnail = url.replace('/original', `/rectcontain${pd}`);
    } else {
      url = getCloudflareImageUrl({
        url: media.staticRaster,
        windowWidth: px,
        width: px,
        blockAnimated: blockAnimated,
        increasedWidth: increasedWidth,
      });

      thumbnail = getCloudflareImageUrl({
        url: media.staticRaster,
        windowWidth: px,
        width: px,
        blockAnimated: blockAnimated,
        increasedWidth: increasedWidth,
      });
    }

    const aspectRatio = getImageAspectRatio({
      w: media.width,
      h: media.height,
    });

    processed.push({
      width: media.width,
      thumbnail: thumbnail,
      original: url,
      aspectRatio: aspectRatio,
    });
  }

  return processed;
};

const getUserAvatarOptimalImageUrl = ({
  staticRaster,
  pixelDensity,
  slowConnection = false,
}: {
  staticRaster: string;
  pixelDensity: number;
  slowConnection?: boolean;
}): string => {
  const pd = Math.max(
    Math.min(Math.floor(pixelDensity), 1),
    slowConnection ? 2 : 3,
  );

  const px = pdToPx[pd - 1];

  return getCloudflareImageUrl({
    url: staticRaster,
    width: px,
    windowWidth: px,
  });
};

const getSpecificallySizedImageUrl = ({
  staticRaster,
  h: _,
  w,
  increasedWidth = false,
  applyWidthLimits = false,
}: {
  staticRaster: string;
  w: number;
  h: number;
  increasedWidth?: boolean;
  applyWidthLimits?: boolean;
}): string => {
  return getCloudflareImageUrl({
    url: staticRaster,
    width: w,
    windowWidth: w,
    increasedWidth,
    applyWidthLimits,
  });
};

export {
  getCloudflareImageUrl,
  getImageAspectRatio,
  getSpecificallySizedImageUrl,
  getUserAvatarOptimalImageUrl,
  processMediasForRendering,
  wrapWithWrpCdnForPrefetching,
};

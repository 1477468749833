import { PlusIcon } from '@primer/octicons-react';
import cn from 'classnames';
import { ApiUser } from 'farcaster-client-data';
import {
  CastClickType,
  SharedAmpEvent,
  StatsigEvent,
  useCreateFollow,
  useGloballyCachedUser,
  useTrackCastClick,
  useTrackEvent,
  useUserLinkHelpers,
} from 'farcaster-client-hooks';
import { FC, memo, MouseEvent, useMemo } from 'react';

import { AvatarImage, AvatarImageProps } from '~/components/avatar/AvatarImage';
import { LinkToProfile } from '~/components/links/LinkToProfile';
import { LinkToProfileWithSummaryTooltip } from '~/components/links/LinkToProfileWithSummaryTooltip';
import { useCurrentUser } from '~/hooks/data/useCurrentUser';
import { useIsSignedIn } from '~/hooks/data/useIsSignedIn';
import { trackError } from '~/utils/errorUtils';

type AvatarProps = Pick<AvatarImageProps, 'className' | 'size'> & {
  user: ApiUser;
  emitEventProps?: StatsigEvent;
  onClick?: (e: MouseEvent) => void;
  withDetailsPopover?: boolean;
  disabled?: boolean;
  loading?: HTMLImageElement['loading'];
  hideFollowButton?: boolean;
  style?: React.CSSProperties;
};

const Avatar: FC<AvatarProps> = memo(
  ({
    user: fallbackUser,
    className,
    emitEventProps,
    onClick,
    withDetailsPopover = false,
    size = 'md',
    disabled = false,
    loading,
    hideFollowButton,
    style,
  }) => {
    const user = useGloballyCachedUser({ fallback: fallbackUser });
    const isSignedIn = useIsSignedIn();

    const content = useMemo(() => {
      if (
        isSignedIn &&
        !disabled &&
        (size === 'md' || size === 'sm') &&
        !hideFollowButton &&
        user.viewerContext?.following === false
      ) {
        return (
          <div className={cn(['relative', className])}>
            <AvatarImage
              size={size}
              imgUrl={user.pfp?.url}
              imgAlt={`${user.displayName} avatar`}
              loading={loading}
              style={style}
            />
            <AvatarFollowUser user={user} />
          </div>
        );
      } else {
        return (
          <AvatarImage
            className={className}
            size={size}
            imgUrl={user.pfp?.url}
            imgAlt={`${user.displayName} avatar`}
            loading={loading}
            style={style}
          />
        );
      }
    }, [
      className,
      disabled,
      hideFollowButton,
      isSignedIn,
      loading,
      size,
      user,
      style,
    ]);

    if (withDetailsPopover) {
      return (
        <LinkToProfileWithSummaryTooltip
          title={user.displayName}
          user={user}
          emitEventProps={emitEventProps}
          onClick={onClick}
        >
          {content}
        </LinkToProfileWithSummaryTooltip>
      );
    }

    if (disabled) {
      return content;
    }

    return (
      <LinkToProfile
        title={user.displayName}
        user={user}
        className="relative inline-block h-min shrink-0"
        emitEventProps={emitEventProps}
        onClick={onClick}
      >
        {content}
      </LinkToProfile>
    );
  },
);

Avatar.displayName = 'Avatar';

const AvatarFollowUser: FC<{ user: ApiUser }> = memo(({ user }) => {
  const currentUser = useCurrentUser();

  const createFollow = useCreateFollow();
  const { trackEvent } = useTrackEvent();
  const trackCastClick = useTrackCastClick();
  const { shouldLinkToUser } = useUserLinkHelpers();

  const linkToUser = useMemo(
    () => shouldLinkToUser({ fid: user.fid }),
    [shouldLinkToUser, user.fid],
  );

  if (!linkToUser || currentUser.fid === user.fid) {
    return null;
  }

  return (
    <>
      <div
        title={`Follow ${user.displayName}`}
        onClick={(e) => {
          try {
            // Should think about whether we need both
            trackEvent(SharedAmpEvent.FollowUser, {
              'is remove': false,
              on: 'avatar',
            });
            trackCastClick({ type: CastClickType.FollowAuthor });
            createFollow({ followee: user, follower: currentUser });
          } catch (error) {
            trackError(error);
            alert(error);
          }
          e.stopPropagation();
          e.preventDefault();
        }}
        className="absolute bottom-0 right-0 mb-[-4px] mr-[-4px] flex h-[20px] w-[20px] items-center justify-center rounded-full border-[2px] bg-[#E2D8F4] border-app hover:bg-[#c1a9df]"
      >
        <PlusIcon className="text-[#8A63D2]" size={12.5} verticalAlign="top" />
      </div>
    </>
  );
});

AvatarFollowUser.displayName = 'AvatarFollowUser';

export { Avatar };
